import "@babel/polyfill";
import { viewport_less_than_360 } from "./modules/viewport";
import { humburger_menu } from "./modules/humburger-menu";
import { anchor_link } from "./modules/anchor-link";
import { anchor_current } from "./modules/anchor-current";
import { data_scroll } from "./modules/data-scroll";
import { tab_menus } from "./modules/tab-menu";
// import { tab_cookie } from "./modules/tab-menu";
import { rwd_image_maps } from "./modules/rwd-image-maps";
import { window_size } from "./modules/theme";
// import { viewport_switcher } from "./modules/viewport-switcher";
import { loading } from "./modules/loading";
import { cardSwiper, mvSwiper, narativeSwiper, seriesMVSwiper, worksMVSwiper } from "./modules/swiper";
import { block_group_accordion } from "./modules/block-group-accordion";
import { fitSticky } from "./modules/sticky";
import { readmore } from "./modules/readmore";
import { mw_scroll_fix } from "./modules/mw-scroll-fix";
import { scroll_hint } from "./modules/scrollhint";

// window size write to html tag
window_size();

// viewport less than 360px
viewport_less_than_360();

// humburger menu
humburger_menu();

// anchor link fix
anchor_link();
anchor_current();

// image map
// rwd_image_maps();

// loading use sessionStorage
// loading();

// tab menu
tab_menus();

// swiper
cardSwiper();
mvSwiper();
narativeSwiper();
seriesMVSwiper();
worksMVSwiper();

// block
block_group_accordion();

// stiky
fitSticky();

// readmore
readmore();

// mw wp form scroll fix
mw_scroll_fix();

// Scroll hint
scroll_hint();
