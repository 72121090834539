/**
 * Swiper
 */

// 最小限の機能しか入っていない
// import Swiper from 'swiper';
// 全ての機能が入っている
// import Swiper from 'swiper/bundle';
// コアバージョンに加えて、ナビゲーションとページネーションを追加する
import Swiper, { Navigation, Pagination, Autoplay, EffectFade } from 'swiper';

// swiper module list
// A11y, Autoplay, Controller, EffectCards, EffectCoverflow, EffectCreative, EffectCube, EffectFade, EffectFlip, FreeMode, Grid, HashNavigation, History, Keyboard, Lazy, Manipulation, Mousewheel, Navigation, Pagination, Parallax, Scrollbar, Swiper, Thumbs, Virtual, Zoom, default

export function cardSwiper() {
  window.addEventListener('DOMContentLoaded', () => {

    const cardSwiper = new Swiper('.p-card .swiper', {
      modules: [Navigation, Pagination, Autoplay],
      slidesPerView: 'auto',
      spaceBetween: 16,
      grabCursor: true,
      watchSlidesProgress: true,
      pagination: {
        el: '.p-card .swiper-pagination',
        clickable: true,
      },
      navigation: {
        nextEl: '.p-card .swiper-button-next',
        prevEl: '.p-card .swiper-button-prev',
      },
      breakpoints: {
        1025: {
          spaceBetween: 32,
        }
      },
    });

  });
}

export function mvSwiper() {
  // https://liginc.co.jp/344056 を参照して、loading()の後に実行するようにした方が良さそう

  window.addEventListener('DOMContentLoaded', () => {

    const elem = document.querySelector('.p-mv-swiper .swiper');
    if (elem === null) return;

    const mvSwiper = new Swiper('.p-mv-swiper .swiper', {
      modules: [Autoplay, EffectFade],
      loop: true,
      speed: 1500, // 切り替わる際の速さ（スライドのスピード）
      effect: 'fade',
      fadeEffect: {
        crossFade: true
      },
      // autoplay: false,
      autoplay: {
        delay: 8000, // 切り替わるまでの時間
        disableOnInteraction: false,
      },
      waitForTransition: false, // スライド切り替えのアニメーションの間は自動再生を止める。true だと1枚目のスライドだけ表示時間が短くなるため、表示時間を揃えたいなら false にする
      roundLengths: true,
      touchEventsTarget: true,
      allowTouchMove: false, // マウスでのスワイプを禁止
      on: {
        slideChange: function (result) {
        },
        slideChangeTransitionStart: function (result) {
        },
        slideChangeTransitionEnd: function (result) {
        },
      },
    });

  });
}

export function narativeSwiper() {
  window.addEventListener('DOMContentLoaded', () => {
    const options = {
      modules: [EffectFade, Pagination, Navigation, Autoplay],
      loop: false,
      speed: 1000, // 切り替わる際の速さ（スライドのスピード）
      effect: 'fade',
      fadeEffect: {
        crossFade: true
      },
      autoplay: {
        delay: 10000, // 切り替わるまでの時間
        // reverseDirection: true,
        stopOnLastSlide: true,
        // disableOnInteraction: true,
      },
      allowTouchMove: false,
      pagination: {
        el: ".slide-pagination",
        type: "fraction",
      },
      navigation: {
        nextEl: '.p-narative .slide-button-next',
        prevEl: '.p-narative .slide-button-prev',
      },
    }
    const breakPoint = 768;
    let narativeSwiper;
    if ( window.innerWidth >= breakPoint ) {
      narativeSwiper = new Swiper('.p-narative-slider', options);
    } else {
      narativeSwiper = undefined;
    }
    window.addEventListener('resize', () => {
      if ( window.innerWidth >= breakPoint ) {
        if( narativeSwiper ) return;
        narativeSwiper = new Swiper('.p-narative-slider', options);
      } else {
        if( !narativeSwiper ) return;
        narativeSwiper.destroy();
        narativeSwiper = undefined;
      }
    }, false);
  }, false);
}


export function seriesMVSwiper() {
  window.addEventListener('DOMContentLoaded', () => {

    const elem = document.querySelector('.p-series-mv-swiper .swiper');
    if (elem === null) return;

    const seriesMVSwiper = new Swiper('.p-series-mv-swiper .swiper', {
      modules: [EffectFade, Autoplay],
      loop: true,
      speed: 1000, // 切り替わる際の速さ（スライドのスピード）
      effect: 'fade',
      fadeEffect: {
        crossFade: true
      },
      autoplay: {
        delay: 5000, // 切り替わるまでの時間
        disableOnInteraction: true,
      },
      allowTouchMove: true,
    });

  });
}

export function worksMVSwiper() {
  window.addEventListener('DOMContentLoaded', () => {

    const elem = document.querySelector('.p-works-slider .swiper');
    if (elem === null) return;

    const worksMVSwiper = new Swiper('.p-works-slider .swiper', {
      modules: [EffectFade, Autoplay],
      loop: true,
      speed: 1000, // 切り替わる際の速さ（スライドのスピード）
      effect: 'fade',
      fadeEffect: {
        crossFade: true
      },
      autoplay: {
        delay: 5000, // 切り替わるまでの時間
        disableOnInteraction: true,
      },
      allowTouchMove: true,
    });

  });
}
