/**
 * Stiky
 */

export function fitSticky() {
  document.addEventListener("DOMContentLoaded", function(){
    const observer = new window.IntersectionObserver( (entry) => {
      // entry.isIntersectingは
      // 赤い半透明の部分がわずかでも画面に入っていればtrue
      if (!entry[0].isIntersecting) {
        // console.log('固定されたよ');
        document.querySelector('.l-header').classList.add('fixed');
      } else {
        // console.log('固定されてないよ');
        document.querySelector('.l-header').classList.remove('fixed');
      }
    });
    observer.observe(document.querySelector('.header-upper'));
    // observer.observe(document.getElementById('site-header'));
  });
}
