/**
 * mw wp form scroll fix
 */

import "jquery";
export function mw_scroll_fix() {

jQuery(function($) {
  let headerheight;
  let navheight;

  if ( $('.error')[0] ) {
    headerheight = document.getElementById('site-header').clientHeight;
    navheight = document.getElementById('p-header-global-nav').clientHeight;
    $('.mw_wp_form').addClass('mw_wp_form_error');
    var errorEl = $('.mw_wp_form').eq(0);
    var position = errorEl.offset().top - headerheight - navheight - 20;
    $('body,html').animate({scrollTop:position}, 100, 'linear');
  }

  // イベントページ（CPT：EVENT）の中で確認画面
  if ( $('.single-events .mw_wp_form_confirm').length ) {
    headerheight = document.getElementById('site-header').clientHeight;
    navheight = document.getElementById('p-header-global-nav').clientHeight;
    var position = $('.mw_wp_form_confirm').offset().top - headerheight - navheight - 20;
    $('body,html').animate({scrollTop:position}, 100, 'linear');
  }

});

}
