/**
 * tab menu
 */

export function tab_menus() {

  window.addEventListener('load', function() {
    const tabsElems = document.querySelectorAll("[data-tabs]");
    // console.log(tabsElems);

    if( tabsElems.length > 0 ){
      for (let i = 0; i < tabsElems.length; i++) {
        let tab = tabsElems[i];
        let tabBtnElems = tab.querySelectorAll("[data-tab]");
        let tabContentElems = tab.querySelectorAll("[data-tab-content]");
        for (let i = 0; i < tabBtnElems.length; i++) {
          let tabBtn = tabBtnElems[i];
          let tabContent = tabContentElems[i];
          tabBtn.addEventListener("click", (e) => {
            e.preventDefault();
            for (let i = 0; i < tabBtnElems.length; i++) {
              tabBtnElems[i].classList.remove('is-active');
              tabBtnElems[i].setAttribute('aria-selected', 'false');
              tabContentElems[i].classList.remove('is-active');
              tabContentElems[i].setAttribute('aria-hidden', 'true');
            }
            tabBtn.classList.add('is-active');
            tabBtn.setAttribute('aria-selected', 'true');
            tabContent.classList.add('is-active');
            tabContent.setAttribute('aria-hidden', 'false');
          });
        }
      }
    }
  });

}
