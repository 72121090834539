/**
 * anchor current
 */

import "jquery";
export function anchor_current() {

  //基準点の準備
  var elemTop = [];

  //現在地を取得するための設定を関数でまとめる
  function PositionCheck(){

    //headerの高さを取得
    var headerH = jQuery("#site-header").outerHeight(true);
    var gNavH = jQuery("#p-header-global-nav").outerHeight(true);
    // var headerH = 0;
    // var gNavH = 0;

    //.scroll-pointというクラス名がついたエリアの位置を取得する設定
    //.scroll-pointクラスがついたエリアからトップまでの距離を計算して設定
    jQuery(".scroll-point").each(function(i) {
      //追従するheader,globalnav分の高さを引き小数点を四捨五入
      elemTop[i] = Math.round(parseInt(jQuery(this).offset().top - headerH - gNavH));
    });
    // console.log({elemTop});
  }

  //ナビゲーションに現在地のクラスをつけるための設定
  //スクロールした際のナビゲーションの関数にまとめる
  function ScrollAnime() {

    //ナビゲーションのliの何番目かを定義するための準備
    // var scroll = Math.round(jQuery(window).scrollTop());
    var scroll = window.scrollY;
    var NavElem = document.querySelectorAll('#menu-secert-menu .current .sub-menu li');
    // console.log(NavElem[5]);

    //全てのナビゲーションの現在地クラスを除去
    jQuery("#menu-secert-menu .current .sub-menu li").removeClass('current');

    var elementLength = NavElem.length;
    // console.log(elementNum);
    // elemTopの数でfor文で形成しなおす！
    //スクロール値が0以上 .scroll-point 1つめの高さ未満

    // if(scroll >= 0 && scroll < elemTop[1]) {
    //   jQuery(NavElem[0]).addClass('current');
    // }

    for( let i = 0; i < elementLength - 1; i++ ) {
      // console.log(i);
      // console.log(elemTop[i+1]);
      if(scroll >= elemTop[i] && scroll < elemTop[i+1]) {
        jQuery(NavElem[i]).addClass('current');
      }
    }

    if(scroll >= elemTop[elementLength - 1]) {
      jQuery(NavElem[elementLength - 1]).addClass('current');
    }
  }

  // 画面をスクロールをしたら動かしたい場合の記述
  jQuery(window).scroll(function () {
    /* 現在地を取得する関数を呼ぶ*/
    PositionCheck();
    /* ナビゲーションに現在地のクラスをつけるための関数を呼ぶ*/
    ScrollAnime();
  });

  // ページが読み込まれたらすぐに動かしたい場合の記述
  jQuery(window).on('load', function () {
    PositionCheck();
    ScrollAnime();
  });

  jQuery(window).resize(function() {
    //リサイズされたときの処理
    PositionCheck()
  });

}
