/**
 * Scroll Hint
 * yarn add scroll-hint
 */

import ScrollHint from 'scroll-hint';

export function scroll_hint() {
  new ScrollHint('.wp-block-flexible-table-block-table.is-scroll-on-mobile', {
    i18n: {
      scrollable: 'スクロールできます'
    },
    // suggestiveShadow: true,
    // scrollHintIconAppendClass: 'scroll-hint-icon-white'
  });
  new ScrollHint('.wp-block-group.is-style-group-scroll-sp', {
    i18n: {
      scrollable: 'スクロールできます'
    },
    // suggestiveShadow: true,
    // scrollHintIconAppendClass: 'scroll-hint-icon-white'
  });
}
