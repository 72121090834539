/**
 * block-group-accordion
 */

export function block_group_accordion() {

  const toggler = document.querySelectorAll('.p-group-accordion-block > .head');
  const togglerBottom = document.querySelectorAll('.p-group-accordion-block > .close');

  let i;
  for (i = 0; i < toggler.length; i++) {
    toggler[i].addEventListener("click", function() {
      let innerEle = this.parentElement.querySelector(".p-group-accordion-block__inner");
      let btnEle = this.parentElement.querySelector(".close");
      this.classList.toggle("active");
      innerEle.classList.toggle("active");
      btnEle.classList.toggle("active");
    });
    togglerBottom[i].addEventListener("click", function() {
      let headEle = this.parentElement.querySelector(".head");
      let innerEle = this.parentElement.querySelector(".p-group-accordion-block__inner");
      this.classList.remove("active");
      headEle.classList.remove("active");
      innerEle.classList.remove("active");
    });
  }

}
