/**
 * anchor link
 */

// import "jquery";
export function anchor_link() {

  // 他のページからの場合
  window.addEventListener('load', function() {
    const url = window.location.href;
    const header = document.getElementById('site-header');
    const globalNav = document.getElementById('p-header-global-nav');
    const headerHeight = header.offsetHeight + globalNav.offsetHeight + 0;

    if (url.indexOf("#") !== -1) {
      const anchor = url.split("#");
      // console.log({anchor});
      const target = document.querySelector('#' + anchor[anchor.length - 1]);
      // console.log({target});
      if ( target !== null ) {
        const position = Math.floor(target.getBoundingClientRect().top + window.pageYOffset) - headerHeight;
        window.scrollTo({
          top: position,
          // behavior: 'smooth'
        });
      }
    }
  });

  // // アンカー位置調整
  // window.addEventListener('DOMContentLoaded', () => {
  //   var links = document.querySelectorAll('a[href*="#"]');
  //   for (var i = 0; i < links.length; i++) {
  //     links[i].addEventListener('click', function(event) {
  //       event.preventDefault();
  //       var href = this.getAttribute("href"),
  //           hrefPageUrl = href.split("#")[0],
  //           currentUrl = location.href,
  //           currentUrl = currentUrl.split("#")[0];

  //       var header = document.getElementById('site-header');
  //       var globalNav = document.getElementById('p-header-global-nav');
  //       var headerHeight = header.offsetHeight + globalNav.offsetHeight + 30;

  //       if(hrefPageUrl == currentUrl){

  //         href = href.split("#"); // #で分割
  //         href = href.pop(); // 最後の配列を取り出す
  //         href = "#" + href;

  //         const target = document.querySelector(href === '#' || href === '' ? 'html' : href),
  //             position = target.offsetTop - headerHeight, //targetの位置を取得
  //             body = document.documentElement;

  //         body.scrollTo({
  //             top: position,
  //             // behavior: "smooth"
  //         });

  //         return false;

  //       } else {

  //       }
  //     });
  //   }
  // });


}
