/**
 * readmore
 */

// import "jquery";
export function readmore() {

  const elements = document.querySelectorAll('.c-readmore-box');

  Array.from(elements).forEach(function(el){

    //ボタンを取得
    const btnWrap = el.querySelector('.c-readmore-box__btn');
    const btn = el.querySelector('.c-readmore-box__btn .wp-block-button .wp-block-button__link');
    //コンテンツを取得
    const content = el.querySelector('.c-readmore-box__content');

    //ボタンクリックでイベント発火
    btn.addEventListener('click', function(){

      if(!content.classList.contains('open')){
        //コンテンツの実際の高さを代入
        //キーワード値（none、max-content等）では動作しないので注意
        content.style.maxHeight = content.scrollHeight + 'px';
        //openクラスを追加
        content.classList.add('open');
        btnWrap.classList.add('open');
        //もっと見るボタンのテキストを設定
        btn.textContent = '閉じる';
      } else {
        //コンテンツの高さを固定値を代入
        content.style.maxHeight = '150px';
        //openクラスを削除
        content.classList.remove('open');
        btnWrap.classList.remove('open');
        //もっと見るボタンのテキストを設定
        btn.textContent = 'もっと見る';
      }
    });
  });
}
